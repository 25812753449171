import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const initialState = {
//   items: [],
//   status: null,
// };

export const productsFetch = createAsyncThunk(
  "products/productsFetch",
  async () => {
    try {
      const response = await axios.get(
        "https://tecnoelectrocomercioonline.com/api/productos/"
      );
      // console.log("response.data:", response.data);
      return response.data;

    } catch (error) {
      console.log(error);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  // initialState,
  initialState: { items: [], status: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productsFetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(productsFetch.fulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "succeeded";
      })
      .addCase(productsFetch.rejected, (state) => {
        state.status = "failed";
      });
  },
  // extraReducers: {
  //   [productsFetch.pending]: (state, action) => {
  //     state.status = "pending";
  //   },
  //   [productsFetch.fulfilled]: (state, action) => {
  //     state.items = action.payload;
  //     state.status = "success";
  //   },
  //   [productsFetch.rejected]: (state, action) => {
  //     state.status = "rejected";
  //   },
  // },
});

export default productsSlice.reducer;