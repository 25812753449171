import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }), // Adjust the base URL as needed
  // baseQuery: fetchBaseQuery({ baseUrl: "https://tecnoelectrocomercioonline.com/api/productos/" }),
  // endpoints: (builder) => ({
  //   getAllProducts: builder.query({
  //     query: () => `products`,
  //   }),
  // }),
  endpoints: (builder) => ({
    fetchProducts: builder.query({
        query: () => 'products/', // Adjust based on your endpoint
    }),
    // Define other endpoints as needed
}),
});

// export const { useGetAllProductsQuery } = productsApi;
export const { useFetchProductsQuery } = productsApi; // You can export hooks for using in components
