import { configureStore } from "@reduxjs/toolkit";
import productsReducer, { productsFetch } from "../utils/productSlice";
import cartReducer, { getTotals } from "../utils/cartSlice";
import { productsApi } from "../utils/productsApi";

const store = configureStore({
    reducer: {
        products: productsReducer,
        cart: cartReducer,
        [productsApi.reducerPath]: productsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(productsApi.middleware),
}
);

store.dispatch(productsFetch());
store.dispatch(getTotals());

export default store;
