import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary'
import { MDBSpinner } from 'mdb-react-ui-kit';
import App from './App';
// State: Redux & Redux components
import { Provider } from "react-redux";
// import { Auth0Provider } from "@auth0/auth0-react";
// Custom Hooks 
import ErrorFallback from './hooks/useErrorFallback';
import store from "./hooks/useStore";
// PWA
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
const AppMemoized = React.memo(App);

root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Provider store={store}>
        <Suspense fallback={(
          <div className='loading mt-5 py-5'>
          <h1 className='text-center textgradient mt-5 py-5'>
            Cargando...
            <MDBSpinner className='mx-2 textgradient' color='info'></MDBSpinner>
          </h1>
        </div>
        )}>
          {/* <Auth0Provider
            domain="dev-kn-mn7ne.us.auth0.com"
            clientId="SmDb9N7M5OgGRyFSZeyYdruGnIcsodkW"
            authorizationParams={{
              redirect_uri: window.location.origin
            }}
          > */}
            <AppMemoized />
          {/* </Auth0Provider> */}
        </Suspense>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode >
);

serviceWorkerRegistration.register();